<template>
  <div id="Dashboard">
    <div class="app flex-row">
      <div class="container">
        <b-row class="justify-content-center">
          <b-col md="10" sm="12">
            <b-card no-body class="mx-4 reg-container">
              <b-card-body class="p-4">
                <div v-if="isPageLoading" class="text-center">
                  <i class="fa fa-cog fa-spin fa-4x"></i>
                </div>
                <div class="container text-center">
                  <div class="row align-items-center">
                    Стан з'єднання: &nbsp;<i class='icon-check success' style="color: greenyellow" :hidden="!isWSConnected">&nbsp;з'єднано з сервером <b :style="{color: 'greenyellow'}" :hidden="(lastMQTTMessageDelay > 5000)">, з'єднано з пристроєм ({{(lastMQTTMessageDelay/1000)}}с)</b><b :style="{color: 'red'}" :hidden="(lastMQTTMessageDelay < 5000)">, пристрій offline <i :hidden="(lastMQTTMessageDelay > 120000)">({{(lastMQTTMessageDelay/1000)}}с)</i></b></i><i :hidden="isWSConnected" class='icon-ban' style="color: red">&nbsp;роз'єднано</i>
                  </div>
                  <div class="row align-items-start">
                    <p>&nbsp;</p>
                  </div>
                  <div class="row align-items-center">
                    Азімут антени: &nbsp;<b :hidden="!isWSConnected">{{compassData.azimuth}}</b>
                  </div>
                  <div class="row align-items-center">
                   Температура щогли: &nbsp;<b :hidden="!isWSConnected">{{compassData.temperature}}</b>
                  </div>
                  <div class="row align-items-start">
                    <p>&nbsp;</p>
                  </div>
                  <div class="row align-items-start">
                    <hr />
                    <b-button class="col" size="sm" :variant="(isEverythingOk)?'success':'secondary'" :disabled="!(isEverythingOk)" :active="(isEverythingOk)" @click="extSwitchToggleClick()">
                      <i class="icon-power"></i> ЖИВЛЕННЯ
                    </b-button>
                  </div>
                  <div class="row align-items-start">
                    <p>&nbsp;</p>
                  </div>
                  <div class="row align-items-start">
                    <b-button class="col" size="sm" :variant="(isEverythingOk)?'success':'secondary'" :disabled="!isEverythingOk" :active="isEverythingOk" @click="leftClick()">
                      ⬅️ ЛІВОРУЧ
                    </b-button>
                    <div class="col container-sm"></div>
                    <b-button class="col" size="sm" :variant="(isEverythingOk)?'warning':'secondary'" :disabled="!isEverythingOk" :active="isEverythingOk" @click="stopClick()">
                      🛑 СТОП <i class="icon-close"></i>
                    </b-button>
                    <div class="col container-sm"></div>
                    <b-button class="col" size="sm" :variant="isEverythingOk?'success':'secondary'" :disabled="!isEverythingOk" :active="isEverythingOk" @click="rightClick()">
                      ПРАВОРУЧ ➡️
                    </b-button>
                  </div>
                  <div class="row align-items-start">
                    <p>&nbsp;</p>
                  </div>
                  <div class="row align-items-left">
                    <div class="col container-sm"></div>
                    <i :hidden="!isWSConnected"> Рух щогли: &nbsp;<b>{{(mastDirection)}}</b></i>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../api/stalk.net";

export default {
  name: "Jupiter",
  components: {},
  data() {
    return {
      isPageLoading: false,
      isWSConnected: false,
      mqttClientsCount: 0,
      lastMQTTMessageAt: 0,
      timerId: 0,
      timerAckId: 0,
      lastMQTTMessageDelay: Date.now(),
      waitForAckAt: 0,
      waitForAckDelay: 0,
      compassData: {
        temperature: 0.0,
        azimuth: 0.0,
        acc: {
          x: 0.0,
          y: 0.0,
          z: 0.0,
        },
        gyro: {
          x: 0.0,
          y: 0.0,
          z: 0.0,
        },
      },
      socket: null,
    };
  },
  methods: {
    sendMessage(msg) {
      this.socket.send(msg);
    },
    parseCompassData(data) {
      //21.03|68.13|-338.440/35.217/634.216|0.092/0.267/-0.015
      let properties = data.split('|');
      for (let i=0; i<(properties.length); i++) {
        switch (i) {
          case 0: this.compassData.temperature = parseFloat(properties[i]); break;
          case 1: this.compassData.azimuth = parseFloat(properties[i]); break;
          case 2: {
            let d = properties[i].split('/');
            this.compassData.acc.x = parseFloat(d[0])
            this.compassData.acc.y = parseFloat(d[1])
            this.compassData.acc.z = parseFloat(d[2])
          } break;
          case 3: {
            let d = properties[i].split('/');
            this.compassData.gyro.x = parseFloat(d[0])
            this.compassData.gyro.y = parseFloat(d[1])
            this.compassData.gyro.z = parseFloat(d[2])
          } break;
        }
      }
    },
    connectWS(ws_host) {
      const self = this;
      console.log("connecting to WS: ", ws_host);
      try {
        this.socket = new WebSocket(ws_host + 'ws/');

        this.socket.onmessage = (event) => {
          console.log(event.data);
          let msg = event.data;
          if (msg.includes("mqtt_clients/")) {
            self.mqttClientsCount = parseInt(msg.replace("mqtt_clients/",""))
          }
          if (msg.includes("compass/")) {
            self.parseCompassData(msg.replace("compass/",""));
            self.lastMQTTMessageDelay = Date.now() - self.lastMQTTMessageAt;
            self.lastMQTTMessageAt = Date.now();
          }
          if (msg.includes("ping/")) {
            msg.replace("ping/","");
            self.lastMQTTMessageDelay = Date.now() - self.lastMQTTMessageAt;
            self.lastMQTTMessageAt = Date.now();
          }
          if (msg.includes("ack/")) {
            msg.replace("ack/","");
            self.lastMQTTMessageDelay = Date.now() - self.lastMQTTMessageAt;
            self.lastMQTTMessageAt = Date.now();
            clearTimeout(self.timerAckId);
            self.waitForAckAt = 0;
          }
        };

        this.socket.onopen = function(event) {
          console.log(event);
          console.log("Successfully connected to the jupiter websocket server, doing auth...");
          self.socket.send("Bearer " + localStorage.getItem("access_token"));
          self.isWSConnected = true;
        }

        this.socket.onclose = function(event) {
          console.log(event);
          console.log("Disconnected from the jupiter websocket server!");
          self.isWSConnected = false;
          /*setInterval(() => {
            if (!self.isWSConnected) {
              self.connectWS(ws_host);
            }
          }, 1000)*/
        }

        this.socket.onError = function(event) {
          console.log(event);
        }
      } catch (e) {
        console.error(e);
      }
    },
    extSwitchToggleClick() {
      let self = this;
      self.sendMessage('ext_switch_toggle');
      self.waitForAckAt = Date.now();
      self.timerAckId = setInterval(() => {
        self.waitForAckDelay = Date.now() - self.waitForAckAt;
      }, 200);
    },
    leftClick() {
      let self = this;
      self.sendMessage('left');
      self.waitForAckAt = Date.now();
      self.timerAckId = setInterval(() => {
        self.waitForAckDelay = Date.now() - self.waitForAckAt;
      }, 200);
    },
    stopClick() {
      let self = this;
      self.sendMessage('stop');
      self.waitForAckAt = Date.now();
      self.timerAckId = setInterval(() => {
        self.waitForAckDelay = Date.now() - self.waitForAckAt;
      }, 200);
    },
    rightClick() {
      let self = this;
      self.sendMessage('right');
      self.waitForAckAt = Date.now();
      self.timerAckId = setInterval(() => {
        self.waitForAckDelay = Date.now() - self.waitForAckAt;
      }, 200);
    },
  },
  mounted() {
    const self = this;
    let ws_host = API.baseURL.replace('http', 'ws');
    /*if (API.baseURL.indexOf("https") >=0) {
      ws_host = API.baseURL.replace('https', 'tcp');
    }*/
    // let ws_host = "ws://51.15.204.9:2000/";
    /*if (API.baseURL.indexOf("https") >=0) {
      ws_host = "wss://51.15.204.9:2000/";
    }*/
    self.timerId = setInterval(() => {
      self.lastMQTTMessageDelay = Date.now() - self.lastMQTTMessageAt;
    }, 200);
    this.connectWS(ws_host);

  },
  beforeMount() {},
  computed: {
    isEverythingOk: function () {
      if (this.waitForAckAt !== 0 && this.waitForAckDelay > 2500) {
        clearTimeout(this.timerAckId);
        this.waitForAckAt = 0;
      }
      return this.isWSConnected && (this.lastMQTTMessageDelay < 5000) && ((this.waitForAckAt === 0) || (this.waitForAckDelay > 2500))
    },
    mastDirection: function() {
      let gyroX = Math.round(this.compassData.gyro.z);
      if (gyroX === 0) {
        return "✋ відсутній";
      }
      if (gyroX < 0) {
        return "праворуч 👉";
      }
      if (gyroX > 0) {
        return "👈 ліворуч";
      }
    }
  },
  watch: {
  },
  notifications: {
    showRegError: {
      title: "Ошибка сканирования",
      type: "error"
    }
  }
};
</script>
